/** @jsx jsx */
import { jsx, useThemeUI, get, Card, Box, Text, Flex, Grid } from 'theme-ui';
import { responsive } from '../../utils/helpers';
import { useMediaQuery } from 'react-responsive'
import { Link } from 'gatsby'
import Logo from '../versus/logo'

const ResultsTable = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 750px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 751px)' })

  const { theme } = useThemeUI()

  const headingSX = {
    backgroundColor: 'primary',
    py: 2,
    px: 4,
    textAlign: 'center',
  }

  const tableSX = {
    width: '100%',
  }

  const bodyCellSX = {
    verticalAlign: 'middle',
    borderBottom: `1px solid ${get(theme, 'colors.background')}`,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5
  }

  const metaSX = {
    paddingBottom: 2,
    borderBottom: '1px solid',
    borderColor: 'background',
    justifyContent: 'space-between',
    lineHeight: '1.2em'
  }

  const boldSX = {
    fontWeight: 700,
    display: 'inline'
  }

  const teamSX = {
    alignItems: 'center',
    justifyContent: responsive('center', 'flex-start', 4),
  }

  const team1SX = {
    ...teamSX,
    flexDirection: responsive('column', 'row-reverse', 4),
    textAlign: responsive('center', 'right', 4),

    'img': {
      marginLeft: responsive('0px', 2, 4)
    }
  }

  const team2SX = {
    ...teamSX,
    flexDirection: responsive('column', 'row', 4),
    textAlign: responsive('center', 'left', 4),

    'img': {
      marginRight: responsive('0px', 2, 4)
    }
  }

  const emblemSX = {
    width: '85px',
    height: '85px',
    marginBottom: responsive(2, '0px', 4),
    flexShrink: 0,
  }

  const scoresSX = {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const nameSX = {
    fontFamily: 'headingquiet',
    fontSize: 7,
  }

  const largeSX = {
    fontFamily: 'headingquiet',
    fontSize: 8,
  }

  const vsSX = {
    ...nameSX,
    marginLeft: 1,
    marginRight: 1
  }

  const Vs = ({scores}) => {
    return scores ? <Text sx={vsSX}>-</Text> : <Text sx={vsSX}>VS</Text>
  }

  const Fixtures = (x) => {

    const { teams } = x;

    if (!teams)
      return null

    const team1 = teams[0];
    const team2 = teams[1];

    return (
      <Grid 
        sx={{color: 'inherit', textDecoration: 'none', cursor: x.onClick ? 'pointer' : 'auto'}} 
        columns={responsive('1fr 50px 1fr', '40% 1fr 40%', 4)} 
        my={3} 
        px={4} 
        as={x.url ? Link : 'div'} 
        to={x.url}
        onClick={x.onClick}
      >
        <Flex sx={team1SX}>
          {team1.crest &&
            <img sx={emblemSX} src={team1.crest} alt={team1.name} />
          }
          {!team1.crest &&
            <Logo name={team1.name} sx={{...emblemSX, marginLeft: responsive('0px', 2, 4)}} />
          }
          
          {isDesktop &&
            <Text sx={nameSX}>{team1.name}</Text>
          }
        </Flex>
        <Flex sx={scoresSX}>
          <Text sx={largeSX}>{team1.score}</Text>
          <Vs scores={team1.score >= 0 && team2.score >= 0}/>
          <Text sx={largeSX}>{team2.score}</Text>
        </Flex>
        <Flex sx={team2SX}>
          {team2.crest &&
            <img sx={emblemSX} src={team2.crest} alt={team2.name} />
          }
          {!team2.crest &&
            <Logo name={team2.name} sx={{...emblemSX, marginRight: responsive('0px', 2, 4)}} />
          }
          {isDesktop &&
            <Text sx={nameSX}>{team2.name}</Text>
          }
        </Flex>
      </Grid>
    )
  }

  return (
    <Card variant="slim">
      
      <Text sx={headingSX} variant="cardHeading">{props.heading}</Text>
      <table sx={tableSX}>
        <tbody>
          {props.data?.map((x,i) => {
            return (
              <tr key={i}>
                <td sx={bodyCellSX} key={i}>
                  <Flex sx={metaSX}>
                    <Text mr={2}><Box sx={boldSX}>{x.competition}</Box> {x.heading}</Text>
                    <Text><Box sx={boldSX}>{x.date}</Box></Text>
                  </Flex>
                  <Fixtures teams={x.fixtures} url={x.url} onClick={x.onClick} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </Card>
  )
}

export default ResultsTable