/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import Versus from '../versus'
import { getLatestMatch } from '../../api_aws/opta'

const Latest = props => {
  const [nextMatch, setNextMatch] = useState()

  useEffect(() => {
    ;(async () => {
      const match = await getLatestMatch()
      if (!match.success) return
      setNextMatch(match.data)
    })()
  }, [setNextMatch])

  if (!nextMatch) return null

  return <Versus {...props} fixtureData={nextMatch} />
}

export default Latest
