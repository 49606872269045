/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx, Grid, Box, Card } from 'theme-ui'
import Layout from '../../components/layout'
import Hero from '../../components/hero'
import Latest from '../../components/versus/latest'
import { responsive } from '../../utils/helpers'
import Select from 'react-select'
import ResultsTable from '../../components/results'
import NextMatch from '../../components/nextMatch'
import { selectStyles } from '../../styles/select'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import {
  competitionOptions,
  getCalendars,
  getMatches,
  sortFixturesByMonth,
} from '../../api_aws/opta'
import Accordian from '../../components/accordion'
import MetaData from '../../components/metadata'

const Results = props => {
  const [calendars, setCalendars] = useState()
  const [calendar, setCalendar] = useState()
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])
  const [position, setPosition] = useState()
  const [league, setLeague] = useState()

  useEffect(() => {
    ;(async () => {
      const calendars = await getCalendars(league)
      if (calendars.success) {
        setCalendars(calendars.data)
        setCalendar(calendars.data[0].id)
      }
    })()
    // handleYearChange(yearOptions()[0])
  }, [league])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setResults([])
      const opta = await getMatches(
        false,
        false,
        position,
        league,
        'played',
        calendar
      )
      if (opta.success) {
        const months = sortFixturesByMonth(opta.data)
        setResults(months ? months : [])
      }
      setLoading(false)
    })()
  }, [position, league, calendar])

  const handleYearChange = e => {
    setCalendar(e.value)
    // setYearFrom(e.value)
    // setYearTo(moment(e.value).add(1, 'year').format('YYYY'))
  }

  const Loading = () => {
    if (!loading) return null

    return <p sx={{ py: 5 }}>Loading...</p>
  }

  return (
    <Layout {...props}>
      <MetaData title="Results" />
      <Hero
        data={[
          {
            type: 'Custom',
            children: (
              <Latest title="Latest Result" variant="large" highlights result />
            ),
          },
        ]}
      />

      <Wrapper>
        <Inner>
          <Grid
            columns={responsive(1, '65% 1fr')}
            gap={10}
            pt={responsive(4, 11)}
          >
            <Box>
              <Grid columns={responsive(1, 3)} gap={3}>
                <Select
                  styles={selectStyles}
                  isSearchable={false}
                  defaultValue={competitionOptions[0]}
                  options={competitionOptions}
                  onChange={e => setLeague(e.value)}
                />
                <Select
                  styles={selectStyles}
                  isSearchable={false}
                  defaultValue={{ value: false, label: 'All Fixtures' }}
                  options={[
                    { value: false, label: 'All Fixtures' },
                    { value: 'home', label: 'Home' },
                    { value: 'away', label: 'Away' },
                  ]}
                  onChange={e => setPosition(e.value)}
                />
                {calendars && (
                  <Select
                    key={league}
                    styles={selectStyles}
                    isSearchable={false}
                    defaultValue={{
                      value: calendars[0].id,
                      label: calendars[0].name,
                    }}
                    options={calendars.map(x => {
                      return {
                        value: x.id,
                        label: x.name,
                      }
                    })}
                    onChange={handleYearChange}
                  />
                )}
              </Grid>

              <Loading />

              {results.length < 1 && !loading && (
                <p sx={{ py: 5 }}>No Results</p>
              )}

              {results.map((x, i) => {
                return (
                  <Box mt={4} mb={4} key={i}>
                    <ResultsTable
                      heading={x.label}
                      data={x.fixtures?.map((x, i) => {
                        return {
                          competition: x.league,
                          heading: `${x.venue}, ${x.time} Kick off`,
                          date: x.date,
                          // url: '/match-centre/report',
                          fixtures: [
                            {
                              name: x.home.shortName
                                ? x.home.shortName
                                : x.home.name,
                              crest: x.home.crest,
                              score: x.home.score,
                            },
                            {
                              name: x.away.shortName
                                ? x.away.shortName
                                : x.away.name,
                              crest: x.away.crest,
                              score: x.away.score,
                            },
                          ],
                        }
                      })}
                    />
                  </Box>
                )
              })}
            </Box>
            <Box mb={5}>
              <Card variant="slim">
                <Accordian heading="Next Match" open noBorder>
                  <Box p={2}>
                    <NextMatch
                      sx={{ mb: 4 }}
                      tickets
                      // travel
                      live
                      location
                      variant="stacked"
                      color="black"
                    />
                  </Box>
                </Accordian>
              </Card>
            </Box>
          </Grid>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default Results
